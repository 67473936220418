<!--
 * @Date: 2021-01-16 10:57:03
 * @LastEditors: frank
 * @LastEditTime: 2021-05-14 19:42:18
 * @FilePath: /shop_frontend/src/views/goodsEvaluate/productList.vue
 * @Description: Description
-->
<template>
  <div>
    <div>
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="inline"
      >
        <a-row>
          <a-col span="8">
            <a-form-model-item label="商品名称">
              <a-input class="w220" v-model="form.name" />
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="商品分类">
              <a-select v-model="form.category" style="width: 220px" allowClear>
                <a-select-option
                  :value="item.id"
                  v-for="item in categoriesList"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="onSubmit"> 筛选 </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="record__table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        bordered
        @change="changeTable"
      >
        <div slot="goods_name" slot-scope="text, record" class="goods__box">
          <img :src="record.picture_url" alt="" class="list__img" />
          <span class="goods_name">{{ record.goods_name }}</span>
        </div>
        <span slot="action" slot-scope="text, record">
          <div class="action_custom">
            <a-icon type="copy" theme="twoTone" @click="onView(record)" />
            <a-icon
              type="plus-circle"
              theme="twoTone"
              @click="writeInfo(record)"
            />
          </div>
        </span>
      </a-table>
    </div>
    <a-modal
      title="添加评论"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
      width="50%"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="infoForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-model-item ref="name" label="会员昵称" prop="member_name">
            <a-input
              v-model="infoForm.member_name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="头像" prop="member_avatar">
            <a-upload
              name="image"
              list-type="picture-card"
              :show-upload-list="false"
              action="/api/member_avatars/"
              :before-upload="beforeUpload"
              :withCredentials="true"
              :headers="headers"
              @change="uploadChange"
              method="get"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                alt="avatar"
                style="display: inline-block; width: 120px; height: 120px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">添加图片</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="评价时间">
            <a-date-picker v-model="infoForm.create_time" format="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item label="评分">
            <a-rate v-model="infoForm.rating" />
          </a-form-model-item>
          <a-form-model-item label="评价内容" prop="content">
            <a-textarea :rows="4" v-model="infoForm.content" />
          </a-form-model-item>
          <a-form-model-item label="是否匿名">
            <a-checkbox v-model="infoForm.is_anonymous">是</a-checkbox>
            <span>勾选将不显示用户头像</span>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    key: "goods_name",
    scopedSlots: { customRender: "goods_name" },
    align: 'center'
  },
  {
    title: "商品货号",
    dataIndex: "goods_number",
    key: "goods_number",
    align: 'center'
  },
  {
    title: "规格",
    dataIndex: "spec_name",
    key: "spec_name",
    align: 'center'
  },
  {
    title: "商品分类",
    dataIndex: "category_name",
    key: "category_name",
    align: 'center'
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    align: 'center'
  },
  {
    title: "库存",
    dataIndex: "inventory",
    key: "inventory",
    align: 'center'
  },
  {
    title: "访客数",
    dataIndex: "uv_count",
    key: "uv_count",
    align: 'center'
  },
  {
    title: "浏览量",
    dataIndex: "pv_count",
    key: "pv_count",
    align: 'center'
  },
  {
    title: "实际评价数",
    dataIndex: "actual_evaluation_count",
    key: "actual_evaluation_count",
    align: 'center'
  },
  {
    title: "添加评价数",
    dataIndex: "add_evaluation_count",
    key: "add_evaluation_count",
    align: 'center'
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: 'center'
  },
];

import Cookies from "js-cookie";

export default {
  data() {
    return {
      loading: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      visible: false,
      imageUrl: "",
      form: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dataSource: [],
      columns,
      page: 1,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      categoriesList: [],
      currentRecord: {},
      infoForm: {
        member_name: "",
        create_time: "",
        rating: "",
        content: "",
        is_anonymous: false,
        member_avatar: "",
      },
      rules: {
        member_name: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入评价内容", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getEvaluationGoods();
    this.getCategories();
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
    params() {
      return {
        page: this.page,
        search: this.form.name,
        category: this.form.category,
      };
    },
  },
  methods: {
    uploadChange({ file }) {
      if (file.status == "done") {
        this.loading = false;
        this.imageUrl = file.response.image;
        this.form.member_avatar = file.response.id;
      }
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 512;
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(512KB)，请修改后重新上传");
        return false;
      } else {
        this.loading = true;
        return true;
      }
          },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert('submit!');
          console.log(this.infoForm, "info");
          this.$axios
            .post("/evaluations/", {
              ...this.infoForm,
              goods_spec: this.currentRecord.goods_spec,
            })
            .then((res) => {
              console.log(res);
              this.visible = false;
              this.$message.success("操作成功");
              this.getEvaluationGoods();
              this.$store.commit("setReload", true);
              this.infoForm = {
                member_name: "",
                create_time: "",
                rating: "",
                content: "",
                is_anonymous: false,
                member_avatar: "",
              };
              this.imageUrl = ''
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getEvaluationGoods() {
      this.$axios
        .get("/evaluation_goods/", { params: this.params })
        .then((res) => {
          console.log(res);
          this.dataSource = res.results || [];
          this.pagination.total = res.count || 0;
        });
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.getEvaluationGoods();
    },
    onSubmit() {
      this.getEvaluationGoods();
    },
    edit(record) {
      console.log(record);
    },
    onView(record) {
      // 查看
      this.$emit("view", record);
    },
    add(record) {
      console.log(record);
    },
    writeInfo(record) {
      this.currentRecord = record;
      this.visible = true;
    },
    getCategories() {
      this.$axios.get("/categories/").then((res) => {
        console.log(res);
        this.categoriesList = res.results || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.record__table {
  padding-top: 10px;
}
.list__img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.goods_name {
  display: inline-block;
  // width: 100px;
}
.goods__box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>